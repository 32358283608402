<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-block text-center"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        v-b-tooltip.hover.right="userData.fullname"
        size="40"
        :src="userData.image"
        :text="avatarText(userData.fullname)"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullname"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'pages-account-setting'}"
      link-class="align-items-center"
    >
      <feather-icon
        size="16"
        icon="EditIcon"
        class="m-50"
      />
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'auth-logout'}"
      link-class="align-items-center"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="m-50"
      />
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
}
</script>
