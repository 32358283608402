export default [
  {
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'PieChartIcon',
    max: 0,
    min: 9999,
    exclude: 0,
  },
  {
    title: 'Tickets',
    route: { name: 'tickets-label', params: { label: 1 } },
    icon: 'InboxIcon',
    max: 0,
    min: 999,
    exclude: 0,
  },
  {
    title: 'Ticket Escalations',
    route: { name: 'tickets-escalate-folder', params: { folder: 'all' } },
    icon: 'InboxIcon',
    max: 999,
    min: 9999,
    exclude: 0,
  },
  {
    title: 'Search PSE',
    route: 'pse',
    icon: 'ArchiveIcon',
    max: 0,
    min: 9999,
    exclude: 0,
  },
  {
    title: 'Agent Reports',
    route: 'reports',
    icon: 'ClipboardIcon',
    max: 0,
    min: 999,
    exclude: 0,
  },
  {
    title: 'Expert Reports',
    route: 'expert-reports',
    icon: 'SidebarIcon',
    max: 0,
    min: 9999,
    exclude: 100,
  },
  {
    title: 'Knowledge Base',
    route: 'knowledge',
    icon: 'BookOpenIcon',
    max: 0,
    min: 9999,
    exclude: 0,
  },
]
