export default [
  {
    title: 'Customer Manager',
    route: 'author-list',
    icon: 'UserIcon',
    max: 0,
    min: 999,
  },
  {
    title: 'FAQ Manager',
    route: 'faq-list',
    icon: 'FileTextIcon',
    max: 0,
    min: 999,
  },
  {
    title: 'Response Template Manager',
    route: 'reply-list',
    icon: 'FilePlusIcon',
    max: 0,
    min: 999,
  },
  {
    title: 'Tag Manager',
    route: 'tag-list',
    icon: 'TagIcon',
    max: 0,
    min: 99,
  },
  {
    title: 'Sub Tag Manager',
    route: 'tag-sub-list',
    icon: 'FlagIcon',
    max: 0,
    min: 99,
  },
  {
    title: 'SLA Manager',
    route: 'sla-manager',
    icon: 'ClockIcon',
    max: 0,
    min: 20,
  },
  {
    title: 'User Manager',
    route: 'users-list',
    icon: 'UsersIcon',
    max: 0,
    min: 20,
  },
  {
    title: 'Unit Manager',
    route: 'unit-list',
    icon: 'ServerIcon',
    max: 0,
    min: 20,
  },
  {
    title: 'Activity Logs',
    route: 'logs',
    icon: 'ActivityIcon',
    max: 0,
    min: 99,
  },
]
